
import { string } from 'vue-types';

import { moduleShape } from '~/types/module';
import hasScrollTriggers from '@/mixins/hasScrollTriggers';

export default {
    mixins: [hasScrollTriggers],
    props: {
        ...moduleShape,
        body: string(),
    },
    data() {
        return {
            playIntro: false,
        };
    },
    methods: {
        /**
         * Use scrolltrigger for text reveal animation.
         */
        setupScrollTriggers() {
            const tl = this.$gsap.timeline({
                scrollTrigger: {
                    scroller: this.scrollingElement,
                    trigger: this.$refs.body,
                    start: 'top 90%',
                    onEnter: () => {
                        this.playIntro = true;
                    },
                },
            });

            this.addScrollTrigger(tl);
        },
    },
};
